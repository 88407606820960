<template>
  <fade-transition :duration="{enter: 300, leave: 300}" mode="out-in">

    <!-- Loading screen -->
    <div v-if="isLoading" key="animation">
      <LoadingScreen />
    </div>

    <!-- Content -->
    <div v-if="!isLoading" key="content">
      <object class="pr-2 md:pr-4 lg:pr-8">
        <embed :src="require(`@/assets/clinical-data/` + clinicalCase.file)" width="100%" height="800"/>
      </object>
    </div>
  </fade-transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { FadeTransition } from 'vue2-transitions'
import LoadingScreen from '@/components/LoadingScreen80vh.vue'

export default {
  name: 'ClinicalDataPage',
  components: {
    FadeTransition,
    LoadingScreen
  },
  data: function() {
    return {
      isLoading: true
    }
  },
  computed: {
    ...mapState({
      clinicalCase: state => state.clinicalData.currentData
    })
  },
  methods: {
    ...mapActions({
      getClinicalData: 'clinicalData/getClinicalData',
      resetClinicalData: 'clinicalData/resetClinicalData'
    })
  },
  created: function() {
    this.getClinicalData(this.$route.params.id)
      .then(() => {
        this.isLoading = false
      })
      .catch(error => {
        if (error.response.status == 403) this.$xmodal.open()
        this.$router.replace({ name: 'ClinicalDatas' })
      })
  },
  beforeDestroy: function() {
    this.resetClinicalData()
  }
}
</script>
